import type { NavigationItem } from "zudoku";

const navigation: NavigationItem[] = [
  {
    label: "Documentation",
    path: "/documentation",
    categories: [
      {
        label: "Getting Started",
        collapsible: false,
        children: [
          { label: "Welcome", path: "welcome" },
          { label: "API Responses", path: "responses" },
          { label: "Incentive Items", path: "items" },
          { label: "Embed", path: "embed" },
          { label: "Migrating (v0 to v1)", path: "migration" },
          { label: "Roadmap", path: "roadmap" },
          { label: "Changelog", path: "changelog" },
        ],
      },
    ],
  },
  {
    label: "API Reference",
    path: "/api",
    categories: [],
  },
  {
    label: "Account",
    path: "/settings/api-keys",
  },
];

export default navigation;
