import type { ZudokuConfig } from "zudoku";
import navigation from "./src/navigation.js";

const config: ZudokuConfig = {
  ui: {
    pageTitle: "%s | Dev Portal",
    headerTitle: "Developers",
    logo: "https://www.rewiringamerica.org/images/logo-rewiring-america.png",
  },
  redirects: [{ from: "/", to: "/documentation", replace: true }],
  authentication: {
    type: "clerk",
    clerkPubKey: "pk_test_ZGVlcC1vd2wtNDAuY2xlcmsuYWNjb3VudHMuZGV2JA",
  },
  navigation,
  apiKeys: {
    enabled: true,
    endpoint: "https://zudoku-rewiringamerica-main-b974659.d2.zuplo.dev",
  },
  apis: {
    type: "url",
    input: `https://zudoku-rewiringamerica-main-ef9c9c0.d2.zuplo.dev/spec.json`,
    path: "/api",
  },
  docs: {
    files: "/pages/**/*.mdx",
  },
};

export default config;
